@mixin has($elements...) {
    @each $element in $elements {
        @at-root #{&}__#{$element} {
            @content;
        }
    }
}

@mixin is($states...) {
    @each $state in $states {
        @at-root #{&}--#{$state} {
            @content;
        }
    }
}

@mixin contentShadow() {
    box-shadow: rgba(0, 0, 0, 0.25) 0 3px 6px;
}

@mixin center($direction: column) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

@mixin centerJustify {
    display: flex;
    justify-content: center;
}

@mixin centerAlign {
    display: flex;
    align-items: center;
}

@mixin dimensions($d) {
    height: $d;
    width: $d;
}

@mixin background-image($url, $size) {
    background-image: url($url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: $size;
}

@mixin ellipsis($lines: 1) {
    display: -webkit-box !important;
    line-clamp: $lines !important;
    -webkit-line-clamp: $lines !important;
    box-orient: vertical !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

@mixin normalizeHeader {
    ion-toolbar {
        --background: #f9f9fc;
    }

    .header-md::after {
        background-image: none !important;
    }
}

@mixin fontPrimary {
    font-family: 'Roboto', sans-serif;
}

@mixin fontSecondary {
    font-family: 'Nunito Sans', sans-serif;
}
